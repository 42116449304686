body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.stack {
  display: block;
}
.container {
  --shared-width: calc(100vw - 200px);
}

.dual-listbox-header {
  font-weight: bold;
  font-size: 20px;
  display: block;
  text-align: center;
}

.flexpage {
  margin: 20px;
  box-sizing: border-box;
  min-width: 300px;
  display: flex;
}
.flexpage div {
  padding-left: 10px;
}
@media (max-width: 768px) {
  .flexpage {
    padding-left: 10px;
  }
}
.flexcol1 {
  width: 5%;
}

.flexcol2 {
  width: 65%;
}

.flexcol3 {
  width: 30%;
}
.flexrow1 {
  display: flex;
  justify-content: space-between;

  flex: 1;
}


.flexHeight {
  display: flex;
  justify-content: center;
  height: calc(100vh - 400px);
  align-items: center;
  border-width: medium;
  flex: 1;
}

.flexHeightDB {
  display: flex;
  justify-content: center;
  height: calc(100vh - 400px);
  align-items: stretch;
  border-width: medium;
  flex-grow: 1;
  min-width: 500px;
}


.flexHeight2 {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  margin-right: 30px;
  margin-bottom: 5px;
}

.flexrow2 {
  display: flex;
  justify-content: space-around;
  height: calc(100vh - 200px);
  align-items: stretch;
  flex: 1;
  text-align: center;
}
.col3row1 {
  display: flex;
  height: 20%;
}
.col3row2 {
  display: flex;
  height: 10%;
}
.col3row3 {
  display: flex;
  height: 70%;
}


.buttoncss {
  font-size : 15px;
  width: 200px;
  height: 50px;
  font-weight: bold;
  cursor: pointer;
  background-color: #194890;
  border-radius: 20px;
  color: white;
}

.buttoncss2 {
  padding: 15px 20px;
  font-size : 12px;
}

.buttoncss3 {
  padding: 4px 12px;
  font-size : 12px;
  background-color: white;
  border: 1px solid #B2BEB5;
  border-radius: 2px;
}

.buttoncss3:hover {
  background-color: #e7e7e7;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.textcounter {
  font-size: 15px;
}
.radiobutton-inline {
  display: inline;
  margin: 0 10px 0 10px;

}
.inputtext {
  width: 195px;
  height: 30px;
  font-size: 15px;
}
.textareacss {
  font-size: 15px;
  font-weight: bold;
  margin: 20px;
  padding-left: 30px;
  border-width: thin;
  white-space: pre-line;
  min-width: calc(100vw - 1200px);
  text-align: left;
}
.hiddencss {
  opacity: 0;
  font-size: 15px;
  font-weight: bold;
  margin: 20px;
  padding-left: 30px;
  border-width: thin;
  white-space: pre-line;
  border: none;
  outline: none;
  resize: none;
  width: 400px;
}

.toptextareacss {
  text-align: left;
  white-space: pre-line;
  font-size: 13px;
  font-weight: bold;
  padding-left: 30px;
  margin: 20px;
}

.grad {
  background-image: linear-gradient(to bottom, #F07822,#194890)
}

.grad2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: pink;
}
.smallfont {
  font-size:15px;
}
.verysmallfont {
  font-size:9px;
}

.bigfont {
  font-size: 100px;
  margin: 12px;
}

.headerImg {
  width: 100px;
  height: 76px;
  vertical-align: middle;
}



/* Popupbox css */
.popupbox{
  width:100%;
  height:100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
};

.popupbox[hidden]{
  display: none;
}

.popupbox-overlay{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:1002;
  background: none repeat scroll 0% 0% #000;
}

.popupbox-content {
  overflow: hidden;
}

.popupbox-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  z-index: 1003;
  position: relative;
}

.popupbox-titleBar {
  overflow: hidden;
  display: block;
  position: relative;
}

.popupbox-btn--close {
  z-index: 1004;
}

.popupbox[data-title='bottom'] .popupbox-content { order: 1 }
.popupbox[data-title='bottom'] .popupbox-titleBar { order: 2 }

/* ----- default theme ----- */

.popupbox-wrapper {
  border-radius: 3px;
  overflow: hidden;
  max-width: 80%;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .9);
  background-color: white;
}

.popupbox-content {
  padding: 20px 24px 30px;
}

.popupbox-titleBar {
  font-weight: bold;
  font-size: 18px;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, .2);
  padding: 11px 37px 11px 24px;
  border-bottom: 1px #ccc solid;
}

.popupbox-btn--close {
  transition: all .5s;
  position: absolute;
  right: 11px;
  top: 11px;
  color: #c1c1c1;
  background: none;
  border: none;
  outline: none;
}

.popupbox-btn--close:hover {
  color: #000;
}

.popupbox.is-active {
  opacity: 1;
  pointer-events: auto;
}

.popupbox[data-title='bottom'] .popupbox-content { box-shadow: 0 1px 1px rgba(0, 0, 0, .3) }
.popupbox[data-title='bottom'] .popupbox-titleBar { box-shadow: none; border-top: 1px #ccc solid }

.prewrapNL {
  white-space:pre-wrap;
}